import {Component, OnInit} from '@angular/core';
import {ExportTempoService} from '../../core/business/service/export-tempo/export-tempo.service';
import {SpinnerService} from '../../core/service/spinner.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {ThemeEnum} from '../../theme/themes';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserBaseService} from "../../core/business/service/user-base/user-base.service";
import {MatDialog} from '@angular/material/dialog';
import {DialogExportTempoProgressComponent} from './progress/dialog-export-tempo-progress.component';

@Component({
	selector: 'app-export-tempo',
	templateUrl: './export-tempo.component.html',
	styleUrls: ['./export-tempo.component.scss']
})
export class ExportTempoComponent implements OnInit {

	annee: string = new Date().getFullYear().toString();

	constructor(private exportTempoService: ExportTempoService, private userService: UserBaseService,
				private spinnerService: SpinnerService,
				private _translator: TranslateService, public snackBar: MatSnackBar,
				private _titleService: Title, public dialog: MatDialog) {
	}

	ngOnInit(): void {
		this._titleService.setTitle(ThemeEnum[environment.theme.toUpperCase()].toString() + ' OGDP > Export Tempo');
	}

	async downloadCsv(): Promise<void> {
		if (this.annee.trim().length === 4) {
			this.snackBar.open(this._translator.instant('EXPORT_TEMPO.SNACKBAR.BEGIN'));
			this.exportTempoService.getSpentTimeByProjectAndUser(this.annee).subscribe();
			this.exportTempoDataDialog(+this.annee);
		} else {
			this.snackBar.open(this._translator.instant('EXPORT_TEMPO.SNACKBAR.END'));
		}
	}

	exportTempoDataDialog(year: number): void {
		this.dialog.open(DialogExportTempoProgressComponent, {
			disableClose: true,
			width: '550px',
			height: '350px',
			data: {year: year}
		});
	}
}
