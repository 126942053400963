import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {io, Socket} from 'socket.io-client';
import {ExportTempoProgressDto} from './export-tempo-progress.dto';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CsvExportDatasource} from "../business/csv-export.datasource";
import {IProjectTimeFinalDto} from "../../../core/business/service/export-tempo/export-tempo.dto";
import {DownloadFileService} from "../../../core/service/download-file.service";

@Component({
	selector: 'app-export-tempo-progress',
	templateUrl: './dialog-export-tempo-progress.component.html',
	styleUrls: ['./dialog-export-tempo-progress.component.scss'],
})
export class DialogExportTempoProgressComponent implements OnInit, OnDestroy {
	private socket: Socket;

	private year: number;

	constructor(
		@Inject(MAT_DIALOG_DATA) data: { year: number; },
		private downloadFileService: DownloadFileService,
		private dialogRef: MatDialogRef<DialogExportTempoProgressComponent>
	) {
		this.year = data.year;
	}

	progress: ExportTempoProgressDto = undefined;

	ngOnInit(): void {
		// the uri param (/progress) is base_url/namespace ('progress' here is the namespace)
		// but this isn't the actual endpoint that's going to be used
		// the actual url will be base_url/path
		this.socket = io('/export-tempo-progress', {
			path: '/api/export-tempo/progress'
		});

		this.socket.on(`${this.year}-progressUpdate`, (data: ExportTempoProgressDto) => {
			this.progress = data;
		});

		this.socket.on(`${this.year}-progressResult`, (data: IProjectTimeFinalDto[]) => {
			this.downloadFileService.localDataToCsvFile(CsvExportDatasource.transformForCsvExport(data), 'export-tempo-' + this.year + '.csv', ';');
			this.socket?.disconnect();
			this.dialogRef.close();
		});
	}

	calculateProgress(total: number, current: number): string {
		if (total === 0) {
			return '0%';
		}
		const percentage: number = (current / total) * 100;
		return Math.round(percentage) + '%';
	}

	ngOnDestroy(): void {
		this.socket?.disconnect();
	}
}
